
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const role = api.getRole();
    const dataMV = reactive({
      data: [] as any,
      page: 1,
      pageSize: 12,
      search: "",
      jenis: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      listJenis: [] as any,
    });

    const getData = async () => {
      // cash("#loadingMV").show();

      // const hide = () => cash("#loadingMV").hide();
      const page = dataMV.page;
      const pageSize = dataMV.pageSize;
      const search = dataMV.search;
      const jenis = dataMV.jenis;
      const url =
        (await api.getConfigFeatureMember("memberMateri", "featureMateri")) +
        "video/" +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize +
        (role === "member" ? "&jenis=" + jenis : "");

      const hitApi = await api.hitApiGet(url);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataMV.data = rs;
      dataMV.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
    };

    const getJenisMateri = async () => {
      // gpf.gLoading.show();

      // const hide = () => gpf.gLoading.hide();
      const url = api.memberMateriJenis;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      dataMV.listJenis = rs;
      dataMV.jenis = rs[0].id;
    };

    const hitFirst = async () => {
      if (role === "member") {
        await getJenisMateri();
        await getData();
      } else {
        await getData();
      }
    };
    hitFirst();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataMV.page = p;
      getData();
    };

    const nextPage = () => {
      dataMV.page = dataMV.page + 1;
      getData();
    };

    const beforePage = () => {
      dataMV.page = dataMV.page - 1;
      getData();
    };

    const lastPage = () => {
      dataMV.page = dataMV.paginate.totalPages;
      getData();
    };

    return {
      getData,
      dataMV,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      role,
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.dataMV;
      ex[f] = val;
    },
  },
});
